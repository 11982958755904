<template>
  <SwapForm
    :addresses="$route.params"
    @switch="$router.push({ name: $route.name, params: $event })"
    :key="successCount"
    @success="successCount++"
  />
</template>

<script>
  import Vue from 'vue'
  import SwapForm from '@/components/forms/SwapForm.vue'

  export default Vue.extend({
    components: { SwapForm },
    data() {
      return {
        successCount: 0,
      }
    },
  })
</script>
