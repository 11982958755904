<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot:default="{ disabled }">
      <pp-card-body class="space-y-4">
        <pp-token-amount-input
          :value="inputAmount"
          @input="handleSwapExactIn"
          show-token
          autocomplete="off"
          :address="addresses.tokenAddress1"
          :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          :disabled="swapExactOutDetails.is('loading') || market.statusIs('inactive')"
          ref="tokenAmountInput1"
        ></pp-token-amount-input>

        <div class="text-center">
          <pp-btn
            data-test="switch-swap-direction"
            icon
            type="button"
            variant="text"
            @click="
              $emit('switch', {
                tokenAddress1: addresses.tokenAddress2,
                tokenAddress2: addresses.tokenAddress1,
              })
            "
            :disabled="market.statusIs('inactive')"
          >
            <svg
              class="h-full w-full"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
              />
            </svg>
          </pp-btn>
        </div>

        <pp-token-amount-input
          :value="outputAmount"
          @input="handleSwapExactOut"
          :validate-balance="false"
          show-token
          autocomplete="off"
          :address="addresses.tokenAddress2"
          :rules="[
            (val) => val > 0 || 'Amount must be larger than 0',
            () => !error || 'Insufficient Liquidity',
          ]"
          :disabled="swapExactInDetails.is('loading') || market.statusIs('inactive')"
          ref="tokenAmountInput2"
        >
          <template #label>
            To
            <span class="text-water-600">(Estimated)</span>
          </template>
        </pp-token-amount-input>
      </pp-card-body>

      <template v-if="$data[`${mode}Details`].is('resolved')">
        <hr />
        <pp-card-body v-if="$data[`${mode}Details`].is('loading')" class="space-y-4">
          <pp-skeleton-loader v-for="n in 5" :key="n" class="h-6"></pp-skeleton-loader>
        </pp-card-body>

        <pp-card-body
          v-else-if="$data[`${mode}Details`].is('resolved')"
          class="space-y-3 overflow-visible"
        >
          <pp-simple-data>
            <template #left>
              <span class="text-water-600">Rate</span>
            </template>

            <template #right>
              <pp-rate
                :pair="[
                  {
                    unit: inputToken.symbol,
                    amount: $data[`${mode}Details`].response.inAmount.formattedAmount(),
                  },
                  {
                    unit: outputToken.symbol,
                    amount: $data[`${mode}Details`].response.outAmount.formattedAmount(),
                  },
                ]"
              ></pp-rate>
            </template>
          </pp-simple-data>

          <pp-simple-data v-if="mode === 'swapExactIn'">
            <template #left>
              <span class="text-water-600">Min. Received</span>
            </template>

            <template #right>
              <pp-formatted-number
                data-test="min-received"
                :value="$data[`${mode}Details`].response.minReceived.formattedAmount()"
                :humanize="false"
                :max-decimal="10"
                class="font-semibold"
              ></pp-formatted-number>
              <pp-token-symbol
                class="ml-1"
                :address="$data[`${mode}Details`].response.minReceived.token.address"
              ></pp-token-symbol>
            </template>
          </pp-simple-data>

          <pp-simple-data v-else>
            <template #left>
              <span class="text-water-600">Max Input</span>
            </template>

            <template #right>
              <pp-formatted-number
                :value="$data[`${mode}Details`].response.maxInput.formattedAmount()"
                :humanize="false"
                :max-decimal="10"
                class="font-semibold"
              ></pp-formatted-number>
              <pp-token-symbol
                class="ml-1"
                :address="$data[`${mode}Details`].response.maxInput.token.address"
              ></pp-token-symbol>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              <span class="text-water-600">Slippage Tolerance</span>
              <pp-tooltip-trigger direction="top">
                The transaction will revert if there is a large, unfavourable price movement before
                it is confirmed.
              </pp-tooltip-trigger>
            </template>

            <template #right>
              <pp-formatted-number
                data-test="slippage-tolerance"
                :value="$data[`${mode}Details`].response.slippage"
                :max-decimal="8"
              ></pp-formatted-number>
              %
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              <span class="text-water-600">Price Impact</span>

              <pp-tooltip-trigger direction="top">
                The difference between market price and estimated execution price due to trade size.
              </pp-tooltip-trigger>
            </template>

            <template #right>
              <pp-formatted-number
                data-test="price-impact"
                :value="100 * $data[`${mode}Details`].response.priceImpact"
                :min-decimal="0"
                :max-decimal="8"
              ></pp-formatted-number>
              %
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              <span class="text-water-600">Swap Fee</span>

              <pp-tooltip-trigger direction="top">
                A portion of each swap goes to the liquidity providers and the treasury.
              </pp-tooltip-trigger>
            </template>

            <template #right>
              <pp-formatted-number
                data-test="swap-fee"
                :value="$data[`${mode}Details`].response.swapFee.formattedAmount()"
                :humanize="false"
                :max-decimal="10"
                class="font-semibold"
              ></pp-formatted-number>
              <pp-token-symbol
                class="ml-1"
                :address="$data[`${mode}Details`].response.swapFee.token.address"
              ></pp-token-symbol>
            </template>
          </pp-simple-data>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-token-approve-container
          :spender-address="market.spenderAddress"
          :spending-amount="inputTokenAmount"
        >
          <template v-slot:default="{ approved }">
            <pp-btn
              data-test="btn"
              v-if="approved"
              size="xl"
              class="flex w-full"
              type="submit"
              :disabled="disabled || market.statusIs('inactive')"
              :loading="$data[`${mode}Details`].is('loading') || form.is('loading')"
            >
              Swap
            </pp-btn>
          </template>
        </pp-token-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
  import Token from '@/domains/entities/Token'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'

  const debounceTime = 500

  export default Vue.extend({
    props: {
      addresses: { type: Object, required: true }, // { tokenAddress1, tokenAddress2 }
    },
    data() {
      return {
        inputAmount: '',
        outputAmount: '',
        mode: 'swapExactIn',
        timeout: null,
        interval: null,
        swapDetailsState: 'idle',
        swapExactInDetails: createState(),
        swapExactOutDetails: createState(),
        form: createState(),
        error: null,
      }
    },
    watch: {
      '$app.state.slippage': function () {
        const { mode } = this
        const handlers = {
          swapExactIn: this.handleSwapExactIn,
          swapExactOut: this.handleSwapExactOut,
        }

        const values = {
          swapExactIn: this.inputAmount,
          swapExactOut: this.outputAmount,
        }

        handlers[mode](values[mode] || 0)
      },
    },
    computed: {
      activeTokenAmount() {
        return this.mode === 'swapExactIn' ? this.inputTokenAmount : this.outputTokenAmount
      },
      market() {
        const { tokenAddress1, tokenAddress2 } = this.addresses

        return PendleAmmMarket.query().findBy('pair', (val) =>
          [tokenAddress1, tokenAddress2].every((addr) => val.includes(addr))
        )
      },
      inputToken() {
        return Token.query().find(this.addresses.tokenAddress1)
      },
      outputToken() {
        return Token.query().find(this.addresses.tokenAddress2)
      },
      inputTokenAmount() {
        return new TokenAmount(this.inputToken, this.inputAmount || '0', false)
      },
      outputTokenAmount() {
        return new TokenAmount(this.outputToken, this.outputAmount || '0', false)
      },
    },
    methods: {
      handleSwapExactIn(val) {
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.inputAmount = val
        this.mode = 'swapExactIn'
        const slippage = this.$app.state.slippage

        if (parseFloat(val) > 0) {
          this.timeout = setTimeout(() => {
            new PromiseHandler(
              () =>
                this.market.contract(this.$store.getters['wallet/identity']).swapExactInDetails({
                  amount: this.inputTokenAmount,
                  slippage: this.$app.state.slippage,
                }),
              this.swapExactInDetails
            )
              .execute({ force: true })
              .then((response) => {
                this.swapExactInDetails.response = { ...response, slippage }
                this.outputAmount = response.outAmount.formattedAmount()
                this.$refs.tokenAmountInput1 && (this.$refs.tokenAmountInput1.interacted = true)
                this.$refs.tokenAmountInput2 && (this.$refs.tokenAmountInput2.interacted = true)
                this.interval = setInterval(() => this.handleSwapExactIn(val), 60000)
              })
          }, debounceTime)
        } else {
          this.swapExactInDetails.state = 'idle'
          this.swapExactInDetails.response = null
          this.outputAmount = ''
        }
      },
      handleSwapExactOut(val) {
        clearInterval(this.interval)
        clearTimeout(this.timeout)
        this.outputAmount = val
        this.error = null
        this.mode = 'swapExactOut'
        const slippage = this.$app.state.slippage

        if (parseFloat(val) > 0) {
          this.timeout = setTimeout(() => {
            new PromiseHandler(
              () =>
                this.market.contract(this.$store.getters['wallet/identity']).swapExactOutDetails({
                  amount: this.outputTokenAmount,
                  slippage: this.$app.state.slippage,
                }),
              this.swapExactOutDetails
            )
              .execute({ force: true })
              .then((response) => {
                this.swapExactOutDetails.response = { ...response, slippage }
                this.inputAmount = response.inAmount.formattedAmount()
                this.$refs.tokenAmountInput1 && (this.$refs.tokenAmountInput1.interacted = true)
                this.$refs.tokenAmountInput2 && (this.$refs.tokenAmountInput2.interacted = true)
                this.interval = setInterval(() => this.handleSwapExactOut(val), 60000)
              }, debounceTime)
              .catch((error) => {
                this.$refs.tokenAmountInput1 && (this.$refs.tokenAmountInput1.interacted = true)
                this.$refs.tokenAmountInput2 && (this.$refs.tokenAmountInput2.interacted = true)
                this.error = error
              })
          })
        } else {
          this.swapExactOutDetails.state = 'idle'
          this.swapExactOutDetails.response = null
          this.inputAmount = ''
        }
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.market
              .contract(this.$store.getters['wallet/identity'])
              [this.mode]({ amount: this.activeTokenAmount, slippage: this.$app.state.slippage }),
          this.form
        )
          .execute()
          .then((response) => {
            this.$notification.success({
              title: 'Swap',
              text: `Swapped ${formatNumber(this.inputAmount)} ${
                this.inputToken.name
              } for ${formatNumber(this.outputAmount)} ${this.outputToken.name}`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Swap',
              text: `Unable to swap ${this.inputToken.name} for ${this.outputToken.name}`,
            })
          })
      },
    },
  })
</script>
